import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { RemoveNullValues } from '../../utils/removeNullableValues';
import { getAllRentables } from './rentable';
import { getOneReservation } from './reservation';
const initialState = {
    status: 'idle',
};
export const reportNonFunctionalDamage = createAsyncThunk('reservationChecks/reportNonFunctionalDamage', async ({ id, ...Data }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/report/nonFunctionalDamage`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getOneReservation(Number(id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const reportFunctionalDamage = createAsyncThunk('reservationChecks/report/FunctionalDamage', async ({ id, ...Data }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/report/functional`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getOneReservation(Number(id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const postReturnByReservationId = createAsyncThunk('reservationChecks/postReturnByReservationId', async ({ id, ...Data }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/return`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getOneReservation(Number(id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const reportPaymentCheck = createAsyncThunk('reservationChecks/reportPaymentCheck', async ({ id, ...Data }) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/reportPaymentCheck`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const checkBannedUser = createAsyncThunk('reservationChecks/checkBannedUser', async (body) => {
    let data;
    try {
        const response = await axios.post(`/customers/checkBanned`, RemoveNullValues(body));
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const returnPaymentCheck = createAsyncThunk('reservationChecks/returnPaymentCheck', async ({ id, ...Data }) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/returnPaymentCheck`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const rentableAvailabilityCheck = createAsyncThunk('reservationChecks/rentableAvailabilityCheck', async (id) => {
    let data;
    try {
        const response = await axios.get(`/reservations/${id}/checkRentableAvailability`);
        data = await response.data;
        return data;
    }
    catch (err) {
        return err;
    }
});
export const sendDraftEmail = createAsyncThunk('reservationChecks/sendDraftEmail', async ({ id, ...Data }) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/sendDraftEmail?language=${Data.language}`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const penaltyReturnCheck = createAsyncThunk('reservationChecks/penaltyReturnCheck', async ({ id, ...Data }) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/penaltyReturnCheck`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const penaltyReturnPost = createAsyncThunk('reservationChecks/penaltyReturnPost', async ({ id, ...Data }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/penaltyKmExceedAmount`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getOneReservation(Number(id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const cancelReservation = createAsyncThunk('reservationChecks/cancelReservation', async (id, thunkApi) => {
    let data;
    try {
        const response = await axios.put(`/reservations/${id}/cancel`);
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(getOneReservation(Number(id)));
            thunkApi.dispatch(getAllRentables({ page: 1, rowsPerPage: 5 }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
const slice = createSlice({
    name: 'reservationChecks',
    initialState,
    reducers: {},
    extraReducers: (builder) => { },
});
export const reducer = slice.reducer;
export default slice;
